import styled, {css} from 'styled-components';
import {device} from '../../../../theme'

export const FooterWrap = styled.footer `
    background-color: ${props => props.bgcolor};
    color :${props => props.white};
    ${props => props.reveal === 'true' && css `
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
    `};
`;

export const FooterTop = styled.div `
    padding-top:40px;
    padding-bottom: 30px;
    @media ${device.medium}{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media ${device.small}{
        padding-top: 15px;
        padding-bottom: 15px;
    }
`;

export const FooterBottom = styled.div `
    padding-bottom: 30px;
`;

export const FooterWidget = styled.div `
    margin-top: ${props => props.mt};
    margin-bottom: ${props => props.mb};
    margin-left: ${props => props.ml};
    margin-right: ${props => props.mr};
     .line-arrow{color: #ee6a21;
        width:30px;
        border-bottom:3px solid #ee6a21;
        font-size: medium;
        font-weight: 900;
        margin: 10px 0;}

    
    ${props => props.responsive && css `
        ${props => props.responsive.large && css `
            @media ${device.large}{
                margin-top: ${props => props.responsive.large.mt};
                margin-bottom: ${props => props.responsive.large.mb};
                margin-left: ${props => props.responsive.large.ml};
                margin-right: ${props => props.responsive.large.mr};
            }
        `}
        ${props => props.responsive.medium && css `
            @media ${device.medium}{
                margin-top: ${props => props.responsive.medium.mt};
                margin-bottom: ${props => props.responsive.medium.mb};
                margin-left: ${props => props.responsive.medium.ml};
                margin-right: ${props => props.responsive.medium.mr};
            }
        `}
        ${props => props.responsive.small && css `
            @media ${device.small}{
                margin-top: ${props => props.responsive.small.mt};
                margin-bottom: ${props => props.responsive.small.mb};
                margin-left: ${props => props.responsive.small.ml};
                margin-right: ${props => props.responsive.small.mr};
            }
        `}
        ${props => props.responsive.xsmall && css `
            @media ${device.xsmall}{
                margin-top: ${props => props.responsive.xsmall.mt};
                margin-bottom: ${props => props.responsive.xsmall.mb};
                margin-left: ${props => props.responsive.xsmall.ml};
                margin-right: ${props => props.responsive.xsmall.mr};
            }
        `}
    `}
`;

export const LogoWidget = styled.figure `
    margin-bottom: 30px;
    margin-top:20px;
`;

export const TextWidget = styled.div `
            color:#8d8c99;

`;

export const FooterWidgetList = styled.ul `
    li{
        a{
            &:hover{color:#8d8c99}
        }
        span{opacity:0}
        &:hover{
            transform: translateX(10px);
            transition-duration: 0.5s;
            span{opacity:1; font-size:14px;}
        }
        &:not(:last-child){
            margin-bottom: 10px;
        }
    }
`
export const Number = styled.h1 `
    color :#6e798c;
    font-size :70px;
    font-weight :800;
    text-align: left;
    // line-height: 1.7em;
    -webkit-font-smoothing: antialiased;
  
`;
