import React from 'react'
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"
import { TiSocialFacebook, TiSocialTwitter, TiSocialInstagram, TiSocialLinkedin } from "react-icons/ti";
import parse from 'html-react-parser'
import { Container } from 'react-bootstrap'
import Logo from '../../../../assets/images/logo/ziraff-w.svg'
import gplayImage from '../../../../assets//images/icons/aeroland-button-google-play.jpg'
import appImage from '../../../../assets//images/icons/aeroland-button-app-store.jpg'
import { Row, Col } from '../../../../components/ui/wrapper'
import Text from '../../../../components/ui/text'
import Anchor from '../../../../components/ui/anchor'
import Button from '../../../../components/ui/button'
import Heading from '../../../../components/ui/heading'
import Social, { SocialLink } from '../../../../components/ui/social'
import {
    FooterWrap,
    FooterTop,
    FooterWidget,
    LogoWidget,
    TextWidget,
    FooterWidgetList,
    FooterBottom,
    Number,
} from './footer.style'

const Footer = ({ copyrightStyle, ...props }) => {
    const siteInfo = useStaticQuery(graphql`
        query FooterSiteQuery {
            site {
                siteMetadata {
                    copyright
                    contact {
                        phone
                        email
                        address
                        website
                    }
                    social {
                        facebook
                        twitter
                        instagram
                        linkedin
                    }
                }
            }
        }      
    `)
    const { phone, email, address, website } = siteInfo.site.siteMetadata.contact;
    const { copyright } = siteInfo.site.siteMetadata;
    const { facebook, twitter, instagram, linkedin } = siteInfo.site.siteMetadata.social;
    return (
        <FooterWrap {...props}>
            <FooterTop>
                <Container>
                    <Row>
                        <Col lg={3} md={4} sm={6}>
                            <FooterWidget responsive={{ medium: { mb: '30px' } }}>
                                <Number>01 </Number>
                                <Heading as="h6" mt="-3px" mb="0px" color="white">Services</Heading>
                                <p className="line-arrow"></p>
                                <FooterWidgetList>
                                    <li><Anchor path="/webapps" color="#8d8c99" hoverstyle="2">Web Apps <span>→</span></Anchor></li>
                                    <li><Anchor path="/mobileapps" color="#8d8c99" hoverstyle="2">Mobile Apps <span>→</span></Anchor></li>
                                    <li><Anchor path="/analytics" color="#8d8c99" hoverstyle="2">Analytics <span>→</span></Anchor></li>
                                    <li><Anchor path="/blockchain" color="#8d8c99" hoverstyle="2">Block Chain <span>→</span></Anchor></li>
                                    <li><Anchor path="/cloud" color="#8d8c99" hoverstyle="2">Cloud <span>→</span></Anchor></li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                        <Col lg={3} md={4} sm={6}>
                            <FooterWidget responsive={{ medium: { mb: '30px' } }}>
                                <Number>02</Number>
                                <Heading as="h6" mt="-3px" mb="0px" color="white">Quick links</Heading>
                                <p className="line-arrow"></p>
                                <FooterWidgetList>
                                    <li><Anchor path="/about-us" color="#8d8c99" hoverstyle="2">About Us <span>→</span></Anchor></li>
                                    <li><Anchor path="/contact-us" color="#8d8c99" hoverstyle="2">Contact us  <span color="#8d8c99">→</span></Anchor></li>
                                    <li><Anchor path="/" color="#8d8c99" hoverstyle="2">Privacy Policy  <span>→</span></Anchor></li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                        <Col lg={3} md={4} sm={6}>
                            <FooterWidget>
                                <Number>03</Number>
                                <Heading as="h6" mt="-3px" mb="0px" color="white">Contact Us</Heading>
                                <p className="line-arrow"></p>
                                <FooterWidgetList>
                                   
                                    {email && <Text mb="10px" color="#8d8c99"><Anchor path={`mailto:${email}`} color="#8d8c99" hoverstyle="2">{email}</Anchor></Text>}
                                     {/* {phone && <Text mb="10px" color="#8d8c99"><Anchor path={`tel:${phone}`} fontWeight="800" color="#8d8c99" hoverstyle="2">{phone}</Anchor></Text>} */}
                                   
                                    <Social space="8px" tooltip={true} shape="rounded" varient="outlined">
                                        {twitter && (
                                            <SocialLink
                                                path={twitter}
                                                title="Twitter">
                                                <TiSocialTwitter />
                                            </SocialLink>
                                        )}
                                        {facebook && (
                                            <SocialLink
                                                path={facebook}
                                                title="Facebook">
                                                <TiSocialFacebook />
                                            </SocialLink>
                                        )}
                                        {linkedin && (
                                            <SocialLink
                                                path={linkedin}
                                                title="Linkedin">
                                                <TiSocialLinkedin />
                                            </SocialLink>
                                        )}
                                    </Social>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                        <Col lg={3} sm={6}>
                            <FooterWidget responsive={{ medium: { mb: '30px' } }}>
                                <LogoWidget>
                                    <img src={Logo} alt="Logo" width="100px" className='img-height' />
                                </LogoWidget>
                                <TextWidget>
                                {address && <Text mb="10px" color="#8d8c99" >{address}</Text>}        
                                    {/* {website && <Text mb="10px"><Anchor path={website} hoverstyle="2">{website}</Anchor></Text>} */}
                                </TextWidget>
                            </FooterWidget>
                        </Col>
                    </Row>
                </Container>
            </FooterTop>
            <FooterBottom>
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} className="text-center text-md-right">
                            {copyright && <Text color="#8d8c99" {...copyrightStyle}>&copy; {new Date().getFullYear()} {parse(copyright)}</Text>}
                        </Col>
                    </Row>
                </Container>
            </FooterBottom>
        </FooterWrap>
    )
}

Footer.propTypes = {
    bgcolor: PropTypes.string,
    reveal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

Footer.defaultProps = {
    bgcolor: '#0d1f36',
    reveal: 'false',
    copyrightStyle: {
        responsive: {
            small: {
                pb: '15px'
            }
        }
    }
};

export default Footer
